/** @jsx jsx */
import "@csstools/normalize.css"
import { jsx, Heading } from "theme-ui"
import { Container } from "theme-ui"
import { FC, Fragment, useEffect, useState, useLayoutEffect } from "react"
import { ViewElement } from "./atoms"
import Helmet from "react-helmet"
import { PageHeader, HEADER_ID } from "./header"
import { PageFooterCommon } from "./footer"
import { GlobalStyles } from "../gatsby-plugin-theme-ui/index"
import SEO from "./seo"
import netlifyIdentity from "netlify-identity-widget"
import { useTranslation } from "react-i18next"
import { TextDirectionProvider } from "../i18n"
import { Global } from "@emotion/core"
import useTabDetect from "../hooks/useTabDetect"

export const PageGlobal: ViewElement<{ path: string; chrome?: boolean }> = ({
  children,
  path,
  chrome,
}) => {
  useTabDetect()
  useEffect(() => {
    netlifyIdentity.init()
    netlifyIdentity.on("init", user => {
      if (!user) {
        netlifyIdentity.on("login", user => {
          if (user && user.role && !user.role.includes("Admin")) {
            //   netlifyIdentity.logout()
            console.log(
              user.role,
              "You need to be given the `Admin` role to login to the Content Management System."
            )
            // } else {
            // }
          }
          document.location.href = "/admin/"
        })
      }
    })
  })

  const {
    i18n: { language },
  } = useTranslation()

  return (
    <TextDirectionProvider locale={language}>
      <Helmet>
        <link rel="stylesheet" href="https://use.typekit.net/wro4twx.css" />
      </Helmet>
      <SEO />
      <GlobalStyles />
      <div id="pi-page-content" sx={{ overflowX: "clip" }}>
        {chrome !== false && <PageHeader path={path} />}
        {children}
        {chrome !== false && <PageFooterCommon />}
      </div>
    </TextDirectionProvider>
  )
}

export const PageFooter: FC = () => <Fragment></Fragment>

export const FooterBlock: ViewElement<{ title?: string }> = ({
  title,
  children,
  ...props
}) => (
  <div
    sx={{
      display: "flex",
      flexDirection: "column",
      lineHeight: "150%",
      fontWeight: 300,
    }}
    {...props}
  >
    {title && (
      <Heading variant="headings.3" sx={{ m: 0 }}>
        {title}
      </Heading>
    )}
    {children}
  </div>
)

export const HeaderSpacing: ViewElement = props => {
  const [height, setHeight] = useState(40)
  useLayoutEffect(() => {
    const header = document.getElementById(HEADER_ID)
    const { bottom, top } = header?.getBoundingClientRect()
    setHeight(bottom - top)
  }, [])
  return (
    <div sx={{ paddingBottom: height, paddingTop: [2, 3, 4] }} {...props} />
  )
}

export const MobileContentBlock: ViewElement = props => (
  <Container sx={{ p: 4 }} {...props} />
)

export const ContentBlock: ViewElement<{ first?: boolean }> = ({
  first,
  ...props
}) => <Container sx={{ p: 4, pt: first ? 9 : 4 }} {...props} />

export const MovementContentBlock: ViewElement<{ first?: boolean }> = ({
  first,
  ...props
}) => (
  <Container
    sx={{ p: ["40px", "55px", "70px"], position: "relative" }}
    {...props}
  />
)

export const ContentBlockSequence: ViewElement = props => (
  <Container sx={{ p: 4, "> *:not(:last-child)": { mb: 5 } }} {...props} />
)

export const BlockHeading: ViewElement = props => (
  <Heading variant="headings.2" sx={{ pb: 3 }} {...props} />
)

export const Content: ViewElement = props => (
  <div sx={{ maxWidth: ["100%", null, 600] }} {...props} />
)

export const MapImageGlobal: ViewElement = () => (
  <Global
    styles={() => ({
      body: {
        backgroundColor: "#280000",
        backgroundImage: "url('" + require("../images/map-pink.jpg") + "')",
        backgroundSize: "cover",
        backgroundPosition: "bottom right",
      },
    })}
  />
)
