/** @jsx jsx */
import { jsx, Heading, Grid, Button } from "theme-ui"
import {
  ViewElement,
  ImageCard,
  Chip,
  Rows,
  Columns,
  BodyText,
  DymaxionCard,
} from "./atoms"
import { Flex, Box } from "theme-ui"
import { sortBy, last, ceil, isInteger, isUndefined } from "lodash"
import {
  Connection,
  ContentFrontmatter,
  ContentNode,
  Edge,
} from "../data/content"
import { NumericDate } from "../data/date"
import { BlockLink } from "./nav"
import { PillarLogo } from "../images/pillars"
import { Trans, useTranslation } from "react-i18next"
import { merge } from "lodash"
import { useMemo, useEffect, useState, Fragment } from "react"
import { navigate } from "gatsby"
import { isPosStrInt, updateQueryString, usePrevious } from "../data/utils"
import { getLanguageName } from "../data/languages"
import { useTextDirection } from "../i18n"
import qs from "query-string"
import { ContentBlock, MovementContentBlock } from "./page"
import { v4 as uuid } from "uuid"
import useThrottle from "../hooks/useThrottle"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons"
import { Tile, TileWrapper } from "../types/movement"

const dymaxiomImage = require("../images/dymaxion-dash.png")

type LocaleResult = {
  locale?: string
  result: "exact" | "variant" | "nomatch"
}

export const lenientlyMatchedLocale = (
  searchLocale: string = "",
  localeOptions: string[] = []
): LocaleResult => {
  // See if there is a translation for this
  let res: LocaleResult = {
    locale: localeOptions?.find(
      t => t.toLowerCase() === searchLocale.toLowerCase()
    ),
    result: "exact",
  }
  // Else, see if there is a translation in a different variant of the same language
  if (!res.locale) {
    try {
      res = {
        locale: localeOptions?.find(
          t =>
            t.toLowerCase().split("-")[0] ===
            searchLocale.toLowerCase().split("-")[0]
        ),
        result: "variant",
      }
    } catch (e) {
      // }
    }
  }

  // Else, if there are any other translations, use those because it's important for there to be some content
  if (!res.locale) {
    res = {
      locale: localeOptions?.[0]?.toLowerCase(),
      result: "nomatch",
    }
  }
  return res
}

export const titleTrim = (title: string, amount: number = 70) => {
  let shortenedTitle = ""
  if (title.length > amount) {
    shortenedTitle = title.substring(0, amount) + "..."
  } else {
    shortenedTitle = (" " + title).slice(1)
  }

  return shortenedTitle
}

export const getOverallLatest = (
  wire: Connection<ContentNode>,
  movement: Connection<ContentNode>,
  movementAnnouncements: Connection<ContentNode>,
  blueprint: Connection<ContentNode>,
  observatory: Connection<ContentNode>,
  limit: number
) => {
  // Set the optional Connection<T> field "date" to the date that is used to sort the nodes on the index page.
  wire.edges = wire.edges
    .filter(e => {
      // Ensure nodes with an undefined associated date are not mapped to avoid runtime errors. If this happens it is because the passed arrays are missing data.
      if (e.node.frontmatter.publishDate == null) {
        return false
      }
      return true
    })
    .map(e => {
      var tempEdge = Object.assign({}, e)
      tempEdge.date = tempEdge.node.frontmatter.publishDate
      return tempEdge
    })

  movement.edges = movement.edges
    .filter(e => {
      if (e.node.frontmatter.startDate == null) {
        return false
      }
      return true
    })
    .map(e => {
      var tempEdge = Object.assign({}, e)
      tempEdge.date = tempEdge.node.frontmatter.startDate
      return tempEdge
    })

  movementAnnouncements.edges = movementAnnouncements.edges
    .filter(e => {
      if (e.node.frontmatter.publishDate == null) {
        return false
      }
      return true
    })
    .map(e => {
      var tempEdge = Object.assign({}, e)
      tempEdge.date = tempEdge.node.frontmatter.publishDate
      return tempEdge
    })

  blueprint.edges = blueprint.edges
    .filter(e => {
      if (e.node.frontmatter.publishDate == null) {
        return false
      }
      return true
    })
    .map(e => {
      var tempEdge = Object.assign({}, e)
      tempEdge.date = tempEdge.node.frontmatter.publishDate
      return tempEdge
    })

  observatory.edges = observatory.edges
    .filter(e => {
      if (e.node.frontmatter.publishDate == null) {
        return false
      }
      return true
    })
    .map(e => {
      var tempEdge = Object.assign({}, e)
      tempEdge.date = tempEdge.node.frontmatter.publishDate
      return tempEdge
    })

  // Now we can sort all these nodes by their associated date (Example: Could be startDate or publishDate).
  let resArray: Connection<ContentNode> = {
    edges: wire.edges
      .concat(movement.edges)
      .concat(movementAnnouncements.edges)
      .concat(blueprint.edges)
      .concat(observatory.edges)
      .sort((a, b) => (a.date! < b.date! ? 1 : -1)),
  }

  resArray.edges.length = limit

  return resArray
}

export const useLocalisedContentSimple = (
  node: ContentNode,
  defaultLocale?: string
): {
  locale: string
  title: string
} => {
  const {
    i18n: { language },
  } = useTranslation()

  const res = useMemo(() => {
    if (!node) return node

    const availableLocales: string[] =
      node.fields.translations?.map(
        t => t.childMarkdownRemark?.frontmatter?.locale
      ) || []
    const foundTranslationLocale = lenientlyMatchedLocale(
      defaultLocale || language,
      availableLocales
    )
    const locale = foundTranslationLocale.locale || defaultLocale || language

    const contentForLocale = node.fields.translations?.find(
      t => t.childMarkdownRemark.frontmatter.locale === locale
    )?.childMarkdownRemark

    return merge(
      {},
      node,
      node?.frontmatter,
      contentForLocale,
      contentForLocale?.frontmatter
    )
  }, [node, defaultLocale, language])

  return res
}

export const useLocalisedContentArray = (
  nodes: ContentNode[] | undefined,
  defaultLocale?: string
) => {
  // Get user's preferred language
  const {
    i18n: { language },
  } = useTranslation()

  const res = useMemo(() => {
    if (!nodes || nodes.length < 1) return nodes

    let finalResults: ContentNode[] = []

    nodes.forEach(node => {
      const availableLocales: string[] =
        node.fields.translations?.map(
          t => t.childMarkdownRemark?.frontmatter?.locale
        ) || []
      const foundTranslationLocale = lenientlyMatchedLocale(
        defaultLocale || language,
        availableLocales
      )
      const locale = foundTranslationLocale.locale || defaultLocale || language
      const languageName = getLanguageName(locale as any)
      const contentForLocale = node.fields.translations?.find(
        t => t.childMarkdownRemark.frontmatter.locale === locale
      )?.childMarkdownRemark

      finalResults.push(
        merge(
          {},
          node,
          node?.frontmatter,
          contentForLocale,
          contentForLocale?.frontmatter,
          {
            locale,
            languageName,
          }
        )
      )
    })

    return finalResults
  }, [nodes, defaultLocale, language])

  return res
}

export const useLocalisedContent = (
  node: ContentNode,
  defaultLocale?: string
): {
  title: string
  abstract?: string
  shortAbstract?: string
  path: string
  html: string
  locale: string
  languageName?: string
  anLink?: string
  anIdentifier?: string
  anSize?: string
  anType?: string
  locales?: Array<{
    locale: string
    path: string
  }>
  translators?: Array<{
    frontmatter: {
      title: string
    }
  }>
} => {
  // Get user's preferred language
  const {
    i18n: { language },
  } = useTranslation()

  const res = useMemo(() => {
    if (!node) return node

    // See if there is any (ideally exact) localised copy for this
    const availableLocales: string[] =
      node.fields.translations?.map(
        t => t.childMarkdownRemark?.frontmatter?.locale
      ) || []
    const foundTranslationLocale = lenientlyMatchedLocale(
      defaultLocale || language,
      availableLocales
    )
    const locale = foundTranslationLocale.locale || defaultLocale || language
    const languageName = getLanguageName(locale as any)
    const contentForLocale = node.fields.translations?.find(
      t => t.childMarkdownRemark.frontmatter.locale === locale
    )?.childMarkdownRemark
    // Find the canonical link for any translated content
    const linkForLocalisedContent = node.fields?.localePaths?.find(
      t => locale === t.locale
    )?.path
    // If possible, show localised properties, and fallback to the node's stuff.
    return merge(
      {},
      node,
      node?.frontmatter,
      contentForLocale,
      contentForLocale?.frontmatter,
      {
        path: linkForLocalisedContent || node,
        locale,
        languageName,
        locales: node.fields?.localePaths,
      }
    )
  }, [node, defaultLocale, language])

  return res
}

export const useLocalisedContentViewer = (
  locale: string,
  localePaths?: Array<{
    locale: string
    path: string
  }>
) => {
  const { i18n } = useTranslation()

  useEffect(function setSiteLocaleToPageLocaleOnLoad() {
    // Pick up the locale from the URL and set the user's locale to it
    // TODO: Don't do this if the user explicitly picked a locale though
    i18n.changeLanguage(locale)
  }, [])

  const lastUiLanguage = usePrevious(i18n.language)

  useEffect(
    function maybeChangePageURLIfLocaleChanges() {
      const uiLanguage = i18n.language || ""
      const uiLanguageChanged =
        !!lastUiLanguage && !!uiLanguage && lastUiLanguage !== uiLanguage
      if (uiLanguageChanged && localePaths) {
        // Look for localised content that vaguely matches the new UI language
        const newLocale = lenientlyMatchedLocale(
          uiLanguage,
          localePaths?.map(l => l.locale)
        )
        if (newLocale.locale && newLocale.result !== "nomatch") {
          // If the newly picked language has some relevant content, redirect the user to it
          const newPath = localePaths.find(
            l => l.locale.toLowerCase() === newLocale!.locale!.toLowerCase()
          )?.path
          if (newPath) {
            navigate(newPath)
          }
        }
      }
    },
    [i18n.language, lastUiLanguage, locale, localePaths]
  )
}

export const Localised: React.FC<{ node }> = ({ node, children }) => {
  const localised = useLocalisedContent(node)
  if (!localised) return null
  return children(localised)
}

export const PrimaryFeatured: ViewElement<{
  node: ContentNode
}> = ({ node, ...props }) => {
  const { title, path, shortAbstract, abstract, locale } = useLocalisedContent(
    node
  )
  const dir = useTextDirection(locale)

  const longestWord = last(sortBy(title.split(" "), x => x.length)) || ""

  return (
    <BlockLink to={path} {...props} sx={{ textAlign: dir.left }}>
      <div
        className="hover-parent"
        sx={{
          display: "flex",
          flexDirection: ["column", null, "row"],
          height: [null, null, 400],
          position: "relative",
        }}
      >
        <Flex
          sx={{
            bg: node.fields.themeColor,
            p: [4, null, 3],
            flexDirection: "column",
            color: "white",
            flexGrow: 0,
            flexShrink: 1,
            justifyContent: "space-between",
            maxHeight: [null, null, 400],
          }}
        >
          <Heading
            variant="headings.2"
            sx={{
              width: [null, null, longestWord.length + "ch"],
              mb: [5, null, 0],
              [dir.marginRight]: "auto",
            }}
          >
            {title}
          </Heading>
          <Heading
            variant="headings.2"
            sx={{
              [dir.marginRight]: "auto",
            }}
          >
            <NumericDate value={node.frontmatter.startDate} />
          </Heading>
        </Flex>
        <ImageCard
          sx={{ flex: 1 }}
          gradient={node.fields.themeColor}
          src={
            !node.frontmatter.featuredImage
              ? dymaxiomImage
              : node.frontmatter.featuredImage.childImageSharp
          }
        >
          <div
            sx={{
              display: "flex",
              height: [240, null, 400],
              p: [2, null, 3],
              boxSizing: "border-box",
              justifyContent: "flex-end",
              alignItems: "flex-start",
            }}
          >
            <FeaturedPillarLogo type={node.fields.type} size={[30, 40, 50]} />
          </div>
        </ImageCard>
      </div>
    </BlockLink>
  )
}

export const LatestPrimaryFeatured: ViewElement<{
  node: ContentNode
  associatedDate?: string
}> = ({ node, associatedDate, ...props }) => {
  const { title, path, locale } = useLocalisedContent(node)
  const dir = useTextDirection(locale)
  if (associatedDate === undefined) {
    associatedDate = ""
  }

  const longestWord = last(sortBy(title.split(" "), x => x.length)) || ""

  return (
    <BlockLink to={path} {...props} sx={{ textAlign: dir.left }}>
      <div
        className="hover-parent"
        sx={{
          display: "flex",
          flexDirection: ["column", null, "row"],
          height: [null, null, 400],
          position: "relative",
        }}
      >
        <Flex
          sx={{
            bg: node.fields.themeColor,
            p: [4, null, 3],
            flexDirection: "column",
            color: "white",
            flexGrow: 0,
            flexShrink: 1,
            justifyContent: "space-between",
            maxHeight: [null, null, 400],
          }}
        >
          <Heading
            variant="headings.2"
            sx={{
              width: [null, null, longestWord.length + "ch"],
              mb: [5, null, 0],
              [dir.marginRight]: "auto",
            }}
          >
            {title}
          </Heading>
          <Heading
            variant="headings.2"
            sx={{
              [dir.marginRight]: "auto",
            }}
          >
            {node.frontmatter.country ? (
              <FeaturedMeta showLogoMobile={false} node={node} />
            ) : (
              <NumericDate value={associatedDate} />
            )}
          </Heading>
        </Flex>
        <ImageCard
          sx={{ flex: 1 }}
          gradient={node.fields.themeColor}
          src={
            !node.frontmatter.featuredImage
              ? dymaxiomImage
              : node.frontmatter.featuredImage.childImageSharp
          }
        >
          <div
            sx={{
              display: "flex",
              height: [240, null, 400],
              p: [2, null, 3],
              boxSizing: "border-box",
              justifyContent: "flex-end",
              alignItems: "flex-start",
            }}
          >
            <FeaturedPillarLogo type={node.fields.type} size={[30, 40, 50]} />
          </div>
        </ImageCard>
      </div>
    </BlockLink>
  )
}

export const SecondaryFeaturedLarge: ViewElement<{
  node: ContentNode
}> = ({ node, ...props }) => {
  const { title, path, shortAbstract, abstract, locale } = useLocalisedContent(
    node
  )
  const dir = useTextDirection(locale)

  return (
    <BlockLink
      to={path}
      {...props}
      className="hover-parent"
      sx={{ textAlign: dir.left }}
    >
      <DymaxionCard overlay={node.fields.themeColor}>
        <div
          sx={{
            height: [160, null, 400],
            p: 3,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            boxSizing: "border-box",
          }}
        >
          <Heading variant="headings.2" sx={{ color: "white" }}>
            {title}
          </Heading>

          <FeaturedMeta
            sx={{ display: ["none", "none", "block"] }}
            node={node}
          />
        </div>
      </DymaxionCard>

      <ImageCard
        src={
          !node.frontmatter.featuredImage
            ? dymaxiomImage
            : node.frontmatter.featuredImage.childImageSharp
        }
        gradient={node.fields.themeColor}
        sx={{
          height: 120,
          display: ["flex", null, "none"],
          flexDirection: "column",
          justifyContent: "flex-end",
          boxSizing: "border-box",
          p: 2,
        }}
      >
        <FeaturedMeta node={node} />
      </ImageCard>
    </BlockLink>
  )
}

export const SecondaryFeatured: ViewElement<{
  node: ContentNode
  dymaxion?: boolean
}> = ({ node, dymaxion, ...props }) => {
  const { title, path, shortAbstract, abstract, locale } = useLocalisedContent(
    node
  )
  const dir = useTextDirection(locale)

  return (
    <BlockLink
      to={path}
      {...props}
      className="hover-parent"
      sx={{ textAlign: dir.left }}
    >
      <ImageCard
        overlay={dymaxion ? node.fields.themeColor : undefined}
        gradient={dymaxion ? undefined : node.fields.themeColor}
        src={
          !node.frontmatter.featuredImage
            ? dymaxiomImage
            : node.frontmatter.featuredImage.childImageSharp
        }
      >
        <div
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            boxSizing: "border-box",
            height: [180, null, 400],
            p: [2, null, 3],
          }}
        >
          <Heading variant="headings.2" sx={{ color: "white" }}>
            {title}
          </Heading>

          <FeaturedMeta node={node} />
        </div>
      </ImageCard>
    </BlockLink>
  )
}

export const SecondaryFeaturedLoader: ViewElement<{
  edges: Edge<ContentNode<ContentFrontmatter>>[]
  defaultShown?: number
}> = ({ edges, defaultShown = 2, ...props }) => {
  const [showing, setShowing] = useState(defaultShown)

  const loadMore = (amount: number) => {
    setShowing(showing + amount)
  }

  useEffect(() => {
    setShowing(defaultShown)
  }, [edges])

  return (
    <Box {...props}>
      <Grid
        gap={[3, 3, 7]}
        columns={[null, null, 2]}
        sx={{
          rowGap: ["15px !important", "15px !important", "35px !important"],
        }}
      >
        {edges.slice(0, showing).map(({ node }) => (
          <SecondaryFeatured key={node.id} node={node} />
        ))}
      </Grid>
      {edges.length > showing && (
        <Button
          sx={{ display: "block", margin: "auto", mt: showing > 0 ? "1em" : 0 }}
          onClick={() => loadMore(2)}
        >
          Load more
        </Button>
      )}
    </Box>
  )
}

export const TertiaryFeatured: ViewElement<{
  node: ContentNode
  truncated: boolean
}> = ({ node, truncated = false, ...props }) => {
  const { title, path, shortAbstract, abstract, locale } = useLocalisedContent(
    node
  )
  const dir = useTextDirection(locale)

  const shortenedDate = node.frontmatter.publishDate?.split("T")[0]

  let bodyText = ""

  if (shortAbstract) {
    bodyText = shortAbstract
  } else if (abstract) {
    bodyText = abstract
  }

  if (truncated) {
    bodyText = titleTrim(bodyText, 90)
  }

  return (
    <Rows
      gap={2}
      {...props}
      className="hover-parent"
      sx={{
        textAlign: dir.left,
      }}
    >
      <BlockLink to={path}>
        <ImageCard
          gradient={node.fields.themeColor}
          src={
            !node.frontmatter.featuredImage
              ? dymaxiomImage
              : node.frontmatter.featuredImage.childImageSharp
          }
        >
          <div
            sx={{
              display: "flex",
              height: 200,
              p: [2, null, 3],
              boxSizing: "border-box",
              justifyContent: "flex-end",
              alignItems: "flex-start",
            }}
          >
            <FeaturedPillarLogo type={node.fields.type} size={30} />
          </div>
        </ImageCard>
      </BlockLink>

      <BlockLink to={path}>
        <Columns
          gap={1}
          sx={{ pt: 2, flexDirection: dir.flexRow, display: "flex" }}
        >
          {node.frontmatter.region && (
            <Chip color="wireHex">
              <Localised node={node.frontmatter.region}>
                {n => n?.title}
              </Localised>
            </Chip>
          )}
          <Chip color="wireHex">
            <Localised node={node.frontmatter.topic}>{n => n?.title}</Localised>
          </Chip>
          {shortenedDate && <Chip color="wireHex">{shortenedDate}</Chip>}
        </Columns>
      </BlockLink>

      <Heading variant="headings.5" sx={{ lineHeight: "100%" }}>
        <BlockLink
          sx={{ ".hover-parent:hover &": { color: node.fields?.themeColor } }}
          to={path}
        >
          {title}
        </BlockLink>
      </Heading>

      <BodyText
        sx={{ ".hover-parent:hover &": { color: node.fields?.themeColor } }}
      >
        <BlockLink to={path}>{bodyText}</BlockLink>
      </BodyText>

      {node.frontmatter.source && (
        <img
          src={node.frontmatter.source?.frontmatter.logo?.publicURL}
          sx={{
            my: 2,
            height: 40,
            mixBlendMode: "multiply",
            filter: "grayscale()",
            opacity: 0.5,
            [dir.marginRight]: "auto",
          }}
        />
      )}
    </Rows>
  )
}

export const TertiaryFeaturedPaged: ViewElement<{
  edges: Edge<ContentNode<ContentFrontmatter>>[]
}> = ({ edges }) => {
  const [initialQueryString, setInitialQueryString] = useState<
    string | undefined
  >(undefined)
  const [pageNo, setPageNo] = useState(1)
  let maxPage = ceil(edges.length / 9)

  useEffect(() => {
    if (window) {
      setInitialQueryString(window.location.search)
    }
  }, [])

  useEffect(() => {
    if (initialQueryString) {
      const parsedQueryString = qs.parse(initialQueryString)

      if (
        parsedQueryString.page &&
        !Array.isArray(parsedQueryString.page) &&
        isPosStrInt(parsedQueryString.page)
      ) {
        const newPageNo = parseInt(parsedQueryString.page)
        setPageNo(newPageNo > maxPage ? maxPage : newPageNo)
      }
    }
  }, [initialQueryString])

  const decreasePageNo = () => {
    updateQueryString("page", (pageNo - 1).toString())
    setPageNo(pageNo - 1)
  }

  const increasePageNo = () => {
    updateQueryString("page", (pageNo + 1).toString())
    setPageNo(pageNo + 1)
  }

  return (
    <Box>
      <Grid gap={5} columns={[1, 2, 3]}>
        {edges.slice(9 * (pageNo - 1), 9 * pageNo).map(({ node }) => (
          <TertiaryFeatured key={node.id} node={node} truncated={false} />
        ))}
      </Grid>
      <Grid
        gap={0}
        columns={"4fr 2fr 4fr"}
        sx={{
          width: ["75%", "50%", "25%"],
          margin: "auto",
          marginTop: "1em",
          p: "0.5em 0em",
          backgroundColor: "#CCF2E7",
          borderRadius: "100px",
        }}
      >
        <Button
          variant="plain"
          sx={{
            fontWeight: pageNo > 1 ? "500" : "500 !important",
            cursor: pageNo > 1 ? "pointer" : "default !important",
            color: pageNo > 1 ? "#111" : "#bfbfbf",
            borderRadius: "0px",
            borderRight: "1px #00bf89 solid",
          }}
          onClick={
            pageNo > 1 ? () => decreasePageNo() : () => setPageNo(pageNo)
          }
        >
          &lt;
        </Button>
        <div
          sx={{
            placeSelf: "center",
            fontSize: "1.5em",
            fontWeight: "600",
            fontFamily: "'IBM Plex Mono',Menlo,monospace",
          }}
        >
          {pageNo}
        </div>
        <Button
          variant="plain"
          sx={{
            fontWeight: pageNo < maxPage ? "500" : "500 !important",
            cursor: pageNo < maxPage ? "pointer" : "default !important",
            color: pageNo < maxPage ? "#111" : "#bfbfbf",
            borderRadius: "0px",
            borderLeft: "1px #00bf89 solid",
          }}
          onClick={
            pageNo < maxPage ? () => increasePageNo() : () => setPageNo(pageNo)
          }
        >
          &gt;
        </Button>
      </Grid>
    </Box>
  )
}

export const FeaturedList: ViewElement = props => <Box {...props} />

export const FeaturedListItem: ViewElement<{
  node: ContentNode
}> = ({ node, ...props }) => {
  const { title, path, locale } = useLocalisedContent(node)
  const dir = useTextDirection(locale)

  return (
    <BlockLink
      to={path}
      {...props}
      className="hover-parent"
      sx={{ textAlign: dir.left }}
    >
      <Rows
        gap={[2, null, 1]}
        sx={{
          pt: 3,
          pb: [3, null, 5],
          borderTop: "2px solid black",
          ".hover-parent:hover &": { color: node.fields?.themeColor },
        }}
      >
        <Heading variant="headings.5">{title}</Heading>
        <Columns
          gap={3}
          sx={{
            fontFamily: "monospace",
            fontWeight: 300,
            fontSize: [13, null, 15],
            flexDirection: dir.flexRow,
            display: "flex",
          }}
        >
          {node.frontmatter.location && (
            <span>{node.frontmatter.location}</span>
          )}
          <span>
            <NumericDate value={node.frontmatter.startDate} />
          </span>
        </Columns>
      </Rows>
    </BlockLink>
  )
}

export const FeaturedListTile: ViewElement<{
  tile: Tile
  themeColor: string
}> = ({ tile, themeColor, ...props }) => {
  const {
    i18n: { language },
  } = useTranslation()

  const defaultLocale = "en"

  if (!tile || !tile.translations) return <div></div>

  const { title, manualLink: path, locale } =
    tile.translations.find(t => t.locale == language) ||
    tile.translations.find(t => t.locale == defaultLocale) ||
    tile.translations[0]

  const dir = useTextDirection(locale)

  return (
    <BlockLink
      to={path}
      {...props}
      className="hover-parent"
      sx={{ textAlign: dir.left }}
    >
      <Rows
        gap={[2, null, 1]}
        sx={{
          pt: 3,
          pb: [3, null, 5],
          borderTop: "2px solid black",
          ".hover-parent:hover &": { color: themeColor },
        }}
      >
        <Heading variant="headings.5">{title}</Heading>
        <Columns
          gap={3}
          sx={{
            fontFamily: "monospace",
            fontWeight: 300,
            fontSize: [13, null, 15],
            flexDirection: dir.flexRow,
            display: "flex",
          }}
        ></Columns>
      </Rows>
    </BlockLink>
  )
}

export const LatestFeaturedListItem: ViewElement<{
  node: ContentNode
  associatedDate?: string
  last?: boolean
}> = ({ node, associatedDate, last, ...props }) => {
  const { title, path, locale } = useLocalisedContent(node)
  const dir = useTextDirection(locale)
  if (associatedDate === undefined) {
    associatedDate = ""
  }
  const shortenedTitle = titleTrim(title)

  return (
    <BlockLink
      to={path}
      {...props}
      className="hover-parent"
      sx={{ textAlign: dir.left, position: "relative" }}
      title={title}
    >
      <Rows
        gap={[2, null, 1]}
        sx={{
          pt: 3,
          pb: last ? 0 : [3, null, "25px"],
          borderTop: "2px solid black",
          ".hover-parent:hover &": { color: node.fields?.themeColor },
        }}
      >
        <Heading sx={{ marginRight: "15%" }} variant="headings.5">
          {shortenedTitle}
        </Heading>
        <Columns
          gap={3}
          sx={{
            fontFamily: "monospace",
            fontWeight: 300,
            fontSize: [13, null, 15],
            flexDirection: dir.flexRow,
            display: "flex",
          }}
        >
          {node.frontmatter.location && (
            <span>{node.frontmatter.location}</span>
          )}
          <span>
            <NumericDate value={associatedDate} />
          </span>
        </Columns>
      </Rows>
      <FeaturedPillarLogo
        type={node.fields.type}
        size={[30, 30, 35]}
        sx={{
          top: "15px",
          right: 0,
          position: "absolute",
          color: node.fields?.themeColor,
        }}
      />
    </BlockLink>
  )
}

export const FeaturedResource: ViewElement<{ node: ContentNode }> = ({
  node,
  themeColor,
  ...props
}) => {
  const { title, description, cta, url } = useLocalisedContent(node)

  return (
    <BlockLink to={url} {...props} className="hover-parent">
      <Rows
        gap={[2, null, 1]}
        sx={{
          pt: 3,
          pb: 3,
          borderTop: "2px solid black",
        }}
      >
        <div sx={{ display: "flex", justifyContent: "space-between" }}>
          <Heading variant="headings.5">{title}</Heading>
          <Heading variant="headings.5" sx={{ color: themeColor }}>
            {cta}
          </Heading>
        </div>
        <div
          sx={{
            fontFamily: "monospace",
            fontWeight: 300,
            fontSize: [15, null, 17],
            pt: 3,
          }}
        >
          {node.frontmatter.description}
        </div>
      </Rows>
    </BlockLink>
  )
}

export const FeaturedArticle: ViewElement<{ node: ContentNode }> = ({
  node,
  ...props
}) => {
  const { title, path, shortAbstract } = useLocalisedContent(node)
  const textStyle = {
    fontFamily: "monospace",
    fontWeight: 300,
    fontSize: [13, null, 15],
  }
  return (
    <BlockLink to={path} {...props} className="hover-parent">
      <Box
        sx={{
          width: "300px",
          borderTop: "3px solid black",
          pt: "4px",
          ".hover-parent:hover &": { color: node.fields?.themeColor },
        }}
      >
        <div
          sx={{
            display: "inline-block",
            marginBottom: "15px",
            justifyContent: "space-between",
          }}
        >
          <Heading sx={{ fontSize: [15, 16, 17] }} variant="headings.5">
            {title}
          </Heading>
          <div sx={textStyle}>
            <NumericDate
              value={node.frontmatter.publishDate || node.frontmatter.startDate}
            />
          </div>
        </div>
        <div sx={textStyle}>{titleTrim(shortAbstract, 130)}</div>
      </Box>
    </BlockLink>
  )
}

export const FeaturedCollection: ViewElement<{ node: ContentNode }> = ({
  node,
  ...props
}) => {
  const { title, path, shortAbstract } = useLocalisedContent(node)
  const textStyle = {
    fontFamily: "monospace",
    fontWeight: 300,
    fontSize: [13, null, 15],
  }
  return (
    <BlockLink to={path} {...props} className="hover-parent">
      <Rows
        gap={[2, null, 1]}
        sx={{
          pt: 3,
          pb: [3, null, 5],
          borderTop: "2px solid black",
          ".hover-parent:hover &": { color: node.fields?.themeColor },
        }}
      >
        <div sx={{ display: "flex", justifyContent: "space-between" }}>
          <Heading variant="headings.5">{title}</Heading>
        </div>
        <div sx={textStyle}>{shortAbstract}</div>
      </Rows>
    </BlockLink>
  )
}

const FeaturedMeta: ViewElement<{
  node: ContentNode
  showLogoMobile?: boolean
}> = ({ node, showLogoMobile = true, ...props }) => {
  const metaContent = node.fields.type
    ? node.fields.type == "observatory-articles" && node.frontmatter.country
      ? node.frontmatter.country
      : node.frontmatter.topic
    : node.frontmatter.topic

  return (
    <div {...props}>
      <Columns
        gap={2}
        flexStyle={{ alignItems: "flex-end" }}
        className="hover-parent"
      >
        <Columns
          sx={{ flex: 1, variant: "headings.2", color: "white" }}
          gap={3}
        >
          <div>
            <Localised node={metaContent}>{n => n?.title}</Localised>
          </div>
          <div>{node.frontmatter.location}</div>
        </Columns>

        {showLogoMobile ? (
          <FeaturedPillarLogo type={node.fields.type} />
        ) : (
          <FeaturedPillarLogo
            sx={{ display: ["none", "none", "block"] }}
            type={node.fields.type}
          />
        )}
      </Columns>
    </div>
  )
}

export const FeaturedPillarLogo: ViewElement<{
  type: string
  size?: number | number[]
}> = ({ type, size = 30, ...props }) => (
  <PillarLogo sx={{ size, color: "white" }} type={type} {...props} />
)

export const TileSplitter: ViewElement<{
  tileWrapper: TileWrapper
  tileComponent: ViewElement<{ tile: Tile }>
  nodeComponent: ViewElement<{ node: ContentNode }>
}> = ({ tileWrapper, tileComponent, nodeComponent }) => {
  if (!tileWrapper.isManual && tileWrapper.tile.campaignArticle) {
    return nodeComponent
  } else {
    return tileComponent
  }
}

export const MovementPrimaryFeaturedWrapper: ViewElement<{
  tileWrapper: TileWrapper
}> = ({ tileWrapper, ...props }) => {
  if (!tileWrapper.isManual && tileWrapper.tile.campaignArticle) {
    return <MovementPrimaryFeatured node={tileWrapper.tile.campaignArticle} />
  } else {
    return <MovementManualPrimaryFeatured tile={tileWrapper.tile} />
  }
}

export const MovementPrimaryFeatured: ViewElement<{ node: ContentNode }> = ({
  node,
  ...props
}) => {
  const { title, path, locale } = useLocalisedContent(node)

  const dir = useTextDirection(locale)

  return (
    <Box sx={{ bg: "movementHexLight" }}>
      <MovementContentBlock
        sx={{
          py: "0px !important",
          px:
            "min(max(calc(calc(100% - 1250px) * 9999), 0px), 70px) !important",
        }}
      >
        <BlockLink to={path} {...props} sx={{ textAlign: dir.left }}>
          <div
            className="hover-parent"
            sx={{
              display: "flex",
              position: "relative",
              width: "100%",
              paddingBottom: "60%",
            }}
          >
            <ImageCard
              sx={{ flex: 1, height: "100%", width: "100%", position: "unset" }}
              src={
                !node.frontmatter.featuredImage
                  ? dymaxiomImage
                  : node.frontmatter.featuredImage.childImageSharp
              }
            >
              <Box
                sx={{
                  bg: "movementHex",
                  position: "absolute",
                  left: ["40px", "55px", "70px"],
                  top: ["25px", "35px", "50px"],
                  padding: "5px",
                  color: "white",
                  fontWeight: 600,
                  fontSize: ["13px", "16px", "20px"],
                  textTransform: "uppercase",
                }}
              >
                {title}
              </Box>
            </ImageCard>
          </div>
        </BlockLink>
      </MovementContentBlock>
    </Box>
  )
}

export const TilePrimary: ViewElement<{ tile: Tile }> = ({
  tile,
  ...props
}) => {
  const {
    i18n: { language },
  } = useTranslation()

  const defaultLocale = "en"

  if (!tile || !tile.translations) return <div></div>

  const { title, manualLink: path, locale } =
    tile.translations.find(t => t.locale == language) ||
    tile.translations.find(t => t.locale == defaultLocale) ||
    tile.translations[0]

  const dir = useTextDirection(locale)

  return (
    <BlockLink to={path} {...props} sx={{ textAlign: dir.left }}>
      <div
        className="hover-parent"
        sx={{
          display: "flex",
          position: "relative",
          width: "100%",
          paddingBottom: "60%",
        }}
      >
        <ImageCard
          sx={{ flex: 1, height: "100%", width: "100%", position: "unset" }}
          src={
            !tile.manualImage ? dymaxiomImage : tile.manualImage.childImageSharp
          }
        >
          <Box
            sx={{
              bg: "movementHex",
              position: "absolute",
              left: ["40px", "55px", "70px"],
              top: ["25px", "35px", "50px"],
              padding: "5px",
              color: "white",
              fontWeight: 600,
              fontSize: ["13px", "16px", "20px"],
              textTransform: "uppercase",
            }}
          >
            {title}
          </Box>
        </ImageCard>
      </div>
    </BlockLink>
  )
}

export const MovementManualPrimaryFeatured: ViewElement<{ tile: Tile }> = ({
  tile,
  ...props
}) => {
  return (
    <Box {...props} sx={{ bg: "movementHexLight" }}>
      <MovementContentBlock
        sx={{
          py: "0px !important",
          px:
            "min(max(calc(calc(100% - 1250px) * 9999), 0px), 70px) !important",
        }}
      >
        <TilePrimary tile={tile} />
      </MovementContentBlock>
    </Box>
  )
}

export const MovementSecondaryFeaturedBlock: ViewElement<{
  tileWrappers: TileWrapper[]
}> = ({ tileWrappers, ...props }) => {
  return (
    <Box sx={{ bg: "movementHexLight" }}>
      <MovementContentBlock
        sx={{
          display: "flex",
          columnGap: ["25px", "32.5px", "40px"],
          rowGap: ["40px", "55px", "70px"],
          flexWrap: "wrap",
        }}
      >
        {tileWrappers.map(tileWrapper => {
          if (!tileWrapper.isManual && tileWrapper.tile.campaignArticle) {
            return (
              <MovementSecondaryFeatured
                node={tileWrapper.tile.campaignArticle}
              />
            )
          } else {
            return <MovementManualSecondaryFeatured tile={tileWrapper.tile} />
          }
        })}
      </MovementContentBlock>
    </Box>
  )
}

export const MovementSecondaryFeatured: ViewElement<{ node: ContentNode }> = ({
  node,
  ...props
}) => {
  const { title, path, locale } = useLocalisedContent(node)

  const dir = useTextDirection(locale)

  return (
    <div
      sx={{
        flex: ["0 100%", "0 100%", "0 calc(50% - 20px)"],
        alignSelf: "end",
        position: "relative",
      }}
    >
      <BlockLink to={path} {...props} sx={{ textAlign: dir.left }}>
        <Heading
          variant="headings.3"
          sx={{
            color: "movementHex",
            width: "90%",
            position: "absolute",
            bottom: "100.5%",
            lineHeight: "0.95em",
            fontSize: ["20px", "24px", "min(2.5vw, 28px)"],
          }}
        >
          {title}
        </Heading>
        <ImageCard
          src={
            !node.frontmatter.featuredImage
              ? dymaxiomImage
              : node.frontmatter.featuredImage.childImageSharp
          }
          sx={{ width: "100%", p: 2, pb: "65%", display: "flex" }}
        />
      </BlockLink>
    </div>
  )
}

export const MovementManualSecondaryFeatured: ViewElement<{ tile: Tile }> = ({
  tile,
  ...props
}) => {
  const {
    i18n: { language },
  } = useTranslation()

  const defaultLocale = "en"

  if (!tile || !tile.translations) return <div></div>

  const { title, manualLink: path, locale } =
    tile.translations.find(t => t.locale == language) ||
    tile.translations.find(t => t.locale == defaultLocale) ||
    tile.translations[0]

  const dir = useTextDirection(locale)

  return (
    <div
      sx={{
        flex: ["0 100%", "0 100%", "0 calc(50% - 20px)"],
        alignSelf: "end",
        position: "relative",
      }}
    >
      <BlockLink to={path} {...props} sx={{ textAlign: dir.left }}>
        <Heading
          variant="headings.3"
          sx={{
            color: "movementHex",
            width: "90%",
            position: "absolute",
            bottom: "100.5%",
            lineHeight: "0.95em",
            fontSize: ["20px", "24px", "min(2.5vw, 28px)"],
          }}
        >
          {title}
        </Heading>
        <ImageCard
          src={
            !tile.manualImage ? dymaxiomImage : tile.manualImage.childImageSharp
          }
          sx={{ width: "100%", p: 2, pb: "65%", display: "flex" }}
        />
      </BlockLink>
    </div>
  )
}

export const MovementAnnouncementBlock: ViewElement<{
  edges: Edge<ContentNode<ContentFrontmatter>>[]
}> = ({ edges, ...props }) => {
  const [showRightArrow, setShowRightArrow] = useState(false)
  const [showLeftArrow, setShowLeftArrow] = useState(false)
  const [casesScroller, setCasesScroller] = useState<HTMLElement | null>(null)
  const throttledFunction = useThrottle(handleScroll, 100)

  const offsetIncrement: number = 361

  function scrollLimit() {
    return casesScroller
      ? casesScroller.scrollWidth - casesScroller.clientWidth
      : 0
  }

  function handleScroll() {
    if (!isUndefined(casesScroller?.scrollLeft)) {
      if (casesScroller.scrollLeft > 0 && !showLeftArrow) {
        setShowLeftArrow(true)
      } else if (casesScroller.scrollLeft <= 0 && showLeftArrow) {
        setShowLeftArrow(false)
      }

      if (casesScroller.scrollLeft < scrollLimit() && !showRightArrow) {
        setShowRightArrow(true)
      } else if (casesScroller.scrollLeft >= scrollLimit() && showRightArrow) {
        setShowRightArrow(false)
      }
    }
  }

  function manualScroll(input: number) {
    if (casesScroller) {
      casesScroller.scroll({
        top: 0,
        left: casesScroller.scrollLeft + input,
        behavior: "smooth",
      })
    }
  }

  useEffect(() => {
    if (!casesScroller) {
      const tempCasesScroller = document.getElementById("scrollerWrapper")
      setCasesScroller(tempCasesScroller)
    } else {
      handleScroll()
    }

    casesScroller?.addEventListener("scroll", throttledFunction)

    return () => {
      casesScroller?.removeEventListener("scroll", throttledFunction)
    }
  }, [casesScroller, showLeftArrow, showRightArrow])

  return (
    <ContentBlock sx={{ position: "relative" }}>
      {showLeftArrow && (
        <FontAwesomeIcon
          icon={faChevronLeft}
          sx={{
            position: "absolute",
            left: ["2.5px", "10px", "10px"],
            width: "50px",
            zIndex: 3,
            color: "#280000",
            top: "calc(50% - 25px)",
            fontSize: "50px",
            cursor: "pointer",
          }}
          onClick={() => manualScroll(-offsetIncrement)}
        />
      )}
      {showRightArrow && (
        <FontAwesomeIcon
          icon={faChevronRight}
          sx={{
            position: "absolute",
            right: ["2.5px", "10px", "10px"],
            width: "50px",
            zIndex: 3,
            color: "#280000",
            top: "calc(50% - 25px)",
            fontSize: "50px",
            cursor: "pointer",
          }}
          onClick={() => manualScroll(offsetIncrement)}
        />
      )}
      <div
        sx={{
          mx: ["15px", "40px", "80px"],
        }}
      >
        <Heading
          variant="headings.3"
          sx={{ mt: "1.25em", mb: "0.75em", color: "movementHex" }}
        >
          <Trans>Announcements</Trans>
        </Heading>
        <div
          id={"scrollerWrapper"}
          className="hiddenScrollbar"
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            gap: "60px",
            overflowX: "scroll",
            msOverflowStyle: "none",
            scrollbarWidth: "none",
          }}
          {...props}
        >
          {edges.map(({ node }) => (
            <FeaturedArticle key={node.id} node={node} />
          ))}
        </div>
      </div>
    </ContentBlock>
  )
}

const JobListing: ViewElement<{
  node: ContentNode
  themeColor?: string
}> = ({ node, themeColor = "accent", ...props }) => {
  const { title, shortAbstract, path, locale } = useLocalisedContent(node)
  const dir = useTextDirection(locale)

  const location = node.frontmatter.region || node.frontmatter.country

  const shortenedDate = node.frontmatter.publishDate?.split("T")[0]

  return (
    <BlockLink to={path} {...props} className="hover-parent">
      <Box
        sx={{
          borderTop: "2px solid black",
          pt: 3,
          pb: [3, null, 5],
          gap: [2, null, 1],
          color: "#280000",
          ":hover": {
            color: themeColor,
          },
        }}
      >
        <div sx={{ display: "flex", justifyContent: "space-between" }}>
          <Heading variant="headings.4">{title}</Heading>
        </div>
        <Columns
          gap={1}
          sx={{ py: "3px", flexDirection: dir.flexRow, display: "flex" }}
        >
          {shortenedDate && <Chip color={themeColor}>{shortenedDate}</Chip>}
          {location && (
            <Chip color={themeColor}>
              <Localised node={location}>{n => n.title}</Localised>
            </Chip>
          )}
        </Columns>
        <div
          sx={{
            fontFamily: "monospace",
            fontWeight: 300,
            fontSize: [13, null, 15],
          }}
        >
          {shortAbstract}
        </div>
      </Box>
    </BlockLink>
  )
}

export const JobsListingBlock: ViewElement<{
  edges: Edge<ContentNode<ContentFrontmatter>>[]
}> = ({ edges }) => {
  return (
    <Box sx={{ maxWidth: "600px", margin: "0 auto", padding: "0px 25px" }}>
      {edges.map(edge => (
        <JobListing node={edge.node} />
      ))}
    </Box>
  )
}
