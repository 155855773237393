/** @jsx jsx */

import { jsx } from "theme-ui"
import { ViewElement } from "./atoms"
import { Trans } from "react-i18next"

export const FieldSetLegend: ViewElement<{ legend?: string }> = ({
  legend,
  children,
}) => {
  return (
    <fieldset sx={{ border: "none", padding: 0 }}>
      {legend && <legend className="visually-hidden"><Trans>{legend}</Trans></legend>}
      {children}
    </fieldset>
  )
}
