import { useEffect, useRef } from "react"

const useTabDetect = () => {
  function handleKeyDown(e: KeyboardEvent) {
    if (
      e.key == "Tab" &&
      document.body &&
      !document.body.classList.contains("is-tabbing")
    ) {
      document.body.classList.add("is-tabbing")
    }
  }

  function handleClick(e: MouseEvent) {
    if (document.body && document.body.classList.contains("is-tabbing")) {
      document.body.classList.remove("is-tabbing")
    }
  }

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown)
    window.addEventListener("mousedown", handleClick)

    return () => {
      window.removeEventListener("keydown", handleKeyDown)
      window.removeEventListener("mousedown", handleClick)
    }
  }, [])
}

export default useTabDetect
